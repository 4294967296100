import PropTypes from "prop-types";
import React from "react";

import styles from "./card.module.css"

const Card = ({children, className }) => (
  <div className={[styles.card, className].join(' ')}>
    {children}
  </div>
)

Card.propTypes = {
  className: PropTypes.string
}

Card.defaultProps = {
  className: ''
}

export default Card;
