import React        from "react";
import { graphql }  from "gatsby"
import { Helmet }   from "react-helmet"

import Layout   from '../components/layout';

import StageUnternehmer from '../components/molecules/stageUnternehmer/stageUnternehmer';
import StageWegweiser   from '../components/molecules/stageWegweiser/stageWegweiser';
import StageKontakt          from '../components/molecules/stageContact/stageContact';

export default ({ data }) => (
  <Layout siteTitle="ChancenCheckin">
    <Helmet
      htmlAttributes={{
        lang: 'de'
      }}
    >
      <meta charSet="utf-8" />
      <title>Für Unternehmer - ChancenCheckin</title>
      <meta name="description" content="Dein Geschäft befindet sich in der Krise? ChancenCheckin –  vielfältige Möglichkeiten für Deine unternehmerische Herausforderung. Schnell. Einfach. Individuell." />
      <link rel="canonical" href="https://www.chancencheckin.de/unternehmer" />
    </Helmet>

    <StageUnternehmer
      imgInspiration={data.imgInspiration.childImageSharp.fluid}
      imgToolbox={data.imgToolbox.childImageSharp.fluid}
      imgCommunity={data.imgCommunity.childImageSharp.fluid}
    />

    <StageWegweiser
      imgWegweiser={data.imgWegweiser.childImageSharp.fluid}
    />

    <StageKontakt />
  </Layout>
)

export const unternehmerImage = graphql`
  fragment unternehmerImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query {
    imgWegweiser: file(relativePath: { eq: "taxiPizza.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    },

    imgInspiration: file(relativePath: { eq: "stageUnternehmer/inspiration.png" }) {
      ...unternehmerImage
    },
    imgToolbox: file(relativePath: { eq: "stageUnternehmer/werkzeug.png" }) {
      ...unternehmerImage
    },
    imgCommunity: file(relativePath: { eq: "stageUnternehmer/community.png" }) {
      ...unternehmerImage
    }
  }
`
