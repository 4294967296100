import React from 'react';
import Img from "gatsby-image";

import Headline from '../../atoms/headline/headline';
import Slugline from '../../atoms/headline/slugline';
import Devider  from '../../atoms/devider/devider';
import Text     from '../../atoms/text/text';
import Grid     from '../../molecules/grid/grid';
import Card     from '../../molecules/card/card';
import Stage    from '../../molecules/stage/stage';

import styles from './stageUnternehmer.module.css';

const StageUnternehmer = ({imgInspiration, imgToolbox, imgCommunity}) => (
    <Stage anchor="unternehmer" styleFull="light">
        <Headline tag="h2" visualStyle="h1" className={styles.headline}>So wird ChancenCheckin UnternehmerInnen helfen:</Headline>
        <Slugline tag="h1">Inspiration - Wegweiser - Community</Slugline>
        <Devider />
        <Grid>
          <Card className={styles.card}>
            <Img className={styles.cardImage} fluid={imgInspiration}  alt="Inspiration" />
            <Headline className={styles.cardHeadline} tag="h3">Inspiration</Headline>
            <Text className={styles.cardText} align="center" fontSize="small">Auf unserer ChancenCheckin-Plattform findest Du Informationen, die Dich dabei unterstützen, Dein Geschäftsmodell zu überdenken und wertvolle neue Ideen zu generieren.</Text>
          </Card>
          <Card className={styles.card}>
            <Headline className={styles.cardHeadline} tag="h3">Wegweiser</Headline>
            <Text className={styles.cardText} align="center" fontSize="small">Du bist Unternehmer:in, deren Geschäft sich im Wandel befindet und sich verändern muss? Dann hilft Dir der Wegweiser, der Dir passende Möglichkeiten für Deine Herausforderungen aufzeigt.</Text>
            <Img className={[styles.cardImage, styles.imageToolBox].join(' ')} fluid={imgToolbox}  alt="Tool-Box" />
          </Card>
          <Card className={styles.card}>
            <Img className={styles.cardImage} fluid={imgCommunity}  alt="Community" />
            <Headline className={styles.cardHeadline} tag="h3">Community</Headline>
            <Text className={styles.cardText} align="center" fontSize="small">
              Langfristig wollen wir Dir neben der Informationssuche mit ChancenCheckin eine lebendige Community bieten, in der Du Dich mit ebenfalls Betroffenen austauschen und gegenseitig unterstützen kannst.
              <br /><br />
              So könnt Ihr nicht nur von- und miteinander lernen, sondern zusätzlich die Stärke der Gemeinschaft für jede:n nutzbar machen!</Text>
          </Card>
        </Grid>
    </Stage>
)
export default StageUnternehmer;
