import PropTypes from "prop-types"
import React from "react"

import styles from "./grid.module.css"

const Grid = ({children, columns, className }) => (
  <div className={[styles.grid, className].join(' ')} style={{'--cols': columns}}>
    {children}
  </div>
)

Grid.propTypes = {
  columns: PropTypes.string,
  className: PropTypes.string
}

Grid.defaultProps = {
  columns: '3',
  className: ''
}

export default Grid;
