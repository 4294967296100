import PropTypes from "prop-types"
import React from "react"

import styles from "./button.module.css"


const Button = ({ children, tagName, href, target, position, onClick, disabled, className }) => {

  const positionStyle = position ? styles[position] : '';
  const TagName = tagName;

  return (
    <div className={[styles.buttonWrap, positionStyle, className].join(' ')}>
      <TagName
        href={href}
        className={styles.button}
        target={target}
        rel={target === '_blank' ? 'noreferrer' : ''}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </TagName>
    </div>
  )
}

Button.propTypes = {
  /** Tag name */
  tagName: PropTypes.oneOf(['a', 'button', 'span', 'div']),
  /** Link URL */
  href: PropTypes.string,
  /** Link target */
  target: PropTypes.string,
  /** Horizontal positioning of the button */
  position: PropTypes.oneOf(['left', 'center', 'right']),
  /** onClick event */
  onClick: PropTypes.func,
  /** disable button */
  disabled: PropTypes.bool,
  /** Additional classname for individual styling in the the parent component */
  className: PropTypes.string
}

Button.defaultProps = {
  tagName: 'a',
  href: null,
  target: '_self',
  position: null,
  onClick: () => {},
  disabled: null,
  className: null
}

export default Button
