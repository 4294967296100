import React from 'react';
import Img from "gatsby-image";

import Button   from '../../atoms/button/button';
import Headline from '../../atoms/headline/headline';
import Slugline from '../../atoms/headline/slugline';
import Devider  from '../../atoms/devider/devider';
import Text     from '../../atoms/text/text';
import Stage    from '../stage/stage';

import styles from './stageWegweiser.module.css';

const StageWegweiser = ({imgWegweiser}) => (
  <Stage styleFull="light" className={styles.stage}>
    <Headline tag="h2" visualStyle="h1">Du bist gefragt!</Headline>
    <Slugline tag="h2">Der Wegweiser hilft Dir dabei schnell und einfach Möglichkeiten zu finden, Dein bestehendes Geschäft zu erweitern sowie krisensicher zu machen und den Aufwand hierbei so gering wie möglich zu halten.</Slugline>
    <Devider />
    <Text align="center">Dieser führt Dich anhand von drei einfach zu beantwortenden Fragen, zu möglichen Angeboten, die Dich in Deiner speziellen Situation unterstützen können. Welche Art der Chance möchtest Du ergreifen? In welcher Region ist dein Geschäft aktiv? Welche Art von Unternehmen hast Du? Als Ergebnis bekommst Du schnell und einfach eine Übersicht der Angebote ohne lange Recherche betreiben zu müssen!</Text>
    <Img className={styles.image} fluid={imgWegweiser} alt="Wegweiser" />
    <Text align="center">Finde mit ChancenCheckin neue Wege mit dem, was Dir zur Verfügung steht! Handle mit unserer Unterstützung schnell, individuell und selbstbestimmt. Wir helfen Dir dabei, Dein Geschäft zukunftsfähig zu machen. Ergreife jetzt Deine Chance und informiere Dich bei uns über Deine Möglichkeiten!</Text>
    <Button href="/">Checke deine Chance!</Button>
  </Stage>
)
export default StageWegweiser;
